.survey-inn-cont {
	@apply my-0 mx-auto bg-surveyContainerBg;
	.survey-title {
		@apply flex justify-between gap-12 items-center;
	}
	.survey-inner-cont {
		@apply p-10 w-8/12 my-0 mx-auto;
		min-width: 725px;
	}
	.number-tag {
		@apply flex bg-primary border-2 border-primary text-white rounded-t-none rounded-b w-max px-3 py-1.5 items-center;
	}
	.number-tag-type {
		@apply flex bg-primary border-2 border-primary text-white rounded-t-none rounded-bl rounded-br-none w-max px-3 py-1.5 self-end items-center;
	}
	.question-type-tag {
		@apply flex border-l-0 border-2 border-primary text-white rounded-t-none rounded-br rounded-bl-none w-max px-3 py-1.5 self-end items-center;
	}
	.label-type-tag {
		@apply flex border-2 border-primary text-white rounded-t-none rounded-br rounded-bl w-max px-3 py-1.5 self-end items-center;
	}
	input,
	textarea {
		@apply mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm;
	}
	select {
		@apply border border-gray-400 rounded focus:outline-none focus:border-primary w-full;
	}
	[type="checkbox"],
	[type="radio"] {
		@apply text-primary;
	}
	button {
		@apply flex bg-primary text-white rounded border-2 border-primary w-max px-3 py-1.5 items-center;
	}
	button.outline-btn {
		@apply bg-white text-primary border-2 border-primary;
	}

	.place-holder-answer {
		@apply mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-500;
	}
	.survey-card {
		@apply pb-4 px-4 bg-white shadow rounded mb-7;
		.question-header {
			@apply flex justify-between mb-4;
			.question-top-options {
				@apply cursor-pointer self-center;
			}
		}
		.question-footer {
			// border-top: 1px solid gray;
			// margin: 3rem 0;
			display: flex;
			justify-content: flex-end;
		}
		input[type="text"] {
			@apply h-12;
		}
		input[type="radio"],
		input[type="checkbox"] {
			height: 15px;
			width: 15px;
			vertical-align: middle;
			padding-left: 0.25rem;
		}
		input[type="radio"] {
			border-radius: 53%;
		}
		input[type="checkbox"] {
			border-radius: 3%;
		}
		.prev-question {
			@apply flex flex-col py-3;
			width: inherit;
		}
		.select-option-cont {
			@apply flex items-center mb-2;
			input[type="text"] {
				height: 2.2rem;
			}
		}
	}
	.add-options-cont {
		@apply flex justify-center;
		.outline-group-btn {
			@apply bg-white text-primary;
		}

		button.outline-group-btn {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
			border-top-width: 2px;
			border-right-width: 2px;
			border-bottom-width: 2px;
			border-left-width: 0;
			width: 128px;
			justify-content: center;
		}
		button.outline-group-btn:last-child {
			border-top-left-radius: 0;
			border-top-right-radius: 0.25rem;
			border-bottom-right-radius: 0.25rem;
			border-bottom-left-radius: 0;
		}
	}

	.switch {
		position: relative;
		display: block;
		vertical-align: top;
		// width: 100px;
		// height: 30px;
		width: 50px;
		height: 20px;
		padding: 3px;
		margin: 0 10px 10px 0;
		background: linear-gradient(to bottom, #eeeeee, #ffffff 25px);
		background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff 25px);
		border-radius: 18px;
		box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
		cursor: pointer;
	}
	.switch-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}
	.switch-label {
		position: relative;
		display: block;
		height: inherit;
		font-size: 10px;
		text-transform: uppercase;
		background: #eceeef;
		border-radius: inherit;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
	}
	.switch-label:before,
	.switch-label:after {
		position: absolute;
		top: 50%;
		margin-top: -0.5em;
		line-height: 1;
		-webkit-transition: inherit;
		-moz-transition: inherit;
		-o-transition: inherit;
		transition: inherit;
	}
	.switch-label:before {
		content: attr(data-off);
		right: 11px;
		color: #aaaaaa;
		text-shadow: 0 1px rgba(255, 255, 255, 0.5);
	}
	.switch-label:after {
		content: attr(data-on);
		left: 11px;
		color: #ffffff;
		text-shadow: 0 1px rgba(0, 0, 0, 0.2);
		opacity: 0;
	}
	.switch-input:checked ~ .switch-label {
		background: #c81f3f;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
	}
	.switch-input:checked ~ .switch-label:before {
		opacity: 0;
	}
	.switch-input:checked ~ .switch-label:after {
		opacity: 1;
	}
	.switch-handle {
		position: absolute;
		top: 4px;
		left: 4px;
		// width: 28px;
		// height: 28px;
		width: 18px;
		height: 18px;
		background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
		background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
		border-radius: 100%;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	}
	.switch-handle:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -6px 0 0 -6px;
		width: 12px;
		height: 12px;
		background: linear-gradient(to bottom, #eeeeee, #ffffff);
		background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff);
		border-radius: 6px;
		box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
	}
	.switch-input:checked ~ .switch-handle {
		// left: 74px;
		left: 28px;
		box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
	}
	/* Transition
          ========================== */
	.switch-label,
	.switch-handle {
		transition: All 0.3s ease;
		-webkit-transition: All 0.3s ease;
		-moz-transition: All 0.3s ease;
		-o-transition: All 0.3s ease;
	}

	.added-options-cont {
		/* margin: 1rem 5rem; */
		width: 65%;
		margin-left: 2rem;
		margin-top: 1rem;
	}
}
.survey button.outline-btn {
	@apply bg-white text-primary border-2 border-primary;
}
.survey button {
	@apply flex bg-primary text-white rounded border-2 border-primary w-max px-3 py-1.5 items-center;
}
