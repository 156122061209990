.items-cont {
	position: relative;
	z-index: 9999999;
	width: inherit;
	.items-sub-cont {
		width: max-content;
		min-width: 100%;
		position: absolute;
		background: white;
		mix-blend-mode: normal;
		border: 0.5px solid rgb(156, 163, 175);
		box-sizing: border-box;
		box-shadow: 0px 5px 5px rgba(255, 255, 255, 0.02);
		border-radius: 6px;
		.option {
			min-height: 2rem;
			font-size: 1rem;
			font-style: normal;
			font-weight: 600;
			line-height: 1.5rem;
			letter-spacing: 0px;
			text-align: left;
			display: flex;
			align-items: center;
			position: relative;
			.add-new {
				width: 100%;
				--height: 45px;
				--border-radius: 6px;
			}
			&:not(:last-child) {
				border-bottom: 1px solid lightgray;
				border-radius: 6px;
			}
		}
		.option.label {
			padding-left: 15px;
			padding-right: 15px;
		}
		.option:hover {
			cursor: pointer;
			user-select: none;
			background-color: lightgray;
			border-radius: 6px;
		}
		.searching {
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.items-sub-cont.searching {
		max-height: 30px;
	}
	.items-sub-cont.has-content {
		max-height: 450px;
		position: absolute;
		overflow: auto;
	}
}
