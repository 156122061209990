.file-input {
	@apply flex relative w-80 h-16 cursor-pointer;
	.drop-area {
		@apply absolute w-full h-full border-2 border-dashed border-primary rounded  leading-4 text-gray-400 font-normal flex items-center justify-center;
		&:hover {
			@apply bg-red-50 border-solid;
		}
		&.dragging {
			@apply bg-red-50 border-solid;
		}
		&.hasFile {
			@apply border-solid;
		}
		div {
			@apply pointer-events-none;
		}
	}
	input {
		@apply w-0 h-0 opacity-0 cursor-pointer hidden;
	}
}
