.activity {
	.activity-tab-cont {
		@apply border border-gray-400 rounded-md divide-y divide-gray-400 md:flex md:divide-y-0 mb-5;
		.tab-list-item-cont {
			@apply relative md:flex-1 md:flex;
			.activity-tab {
				@apply group flex items-center w-full;
				.activity-tab-in {
					@apply px-6 py-4 flex items-center text-sm font-medium;
				}
			}
		}
	}
}
