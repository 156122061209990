@use "./style/form_elements";

table {
	font-family: arial, sans-serif;
	border-collapse: collapse;
	width: 100%;
}

td,
th {
	/* border: 1px solid #dddddd; */
	border: 1px solid #eeeded;
	text-align: left;
	padding: 8px;
}

tr:nth-child(even) {
	/* background-color: #dddddd; */
	background-color: #eeeded;
}

.card {
	@apply p-4 bg-white shadow rounded;
}

.rfp-item {
	@apply border cursor-pointer my-5;
}

.rfp-item:hover {
	@apply border-primary;
}

.wizard {
	.w-tab {
		@apply cursor-pointer border-transparent text-gray-400 flex items-center py-2 relative pl-9 pr-0;

		&.disabled {
			@apply pointer-events-none filter brightness-110;
		}
		&.incomplete {
		}
		&.complete {
			@apply border-primary text-gray-400;
		}
		&:hover {
			@apply filter brightness-110;
		}
		&.true {
			@apply border-primary text-primary;
		}
	}
}

.tab {
	@apply border-b-2 cursor-pointer border-transparent text-gray-400 px-3 flex items-center py-2;
	&:hover {
		@apply bg-gray-200 rounded-t-md;
	}
	&.true {
		@apply border-primary;
	}
	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}

a {
	color: rgb(0, 140, 255) !important;
}

/* These are admin welcome page header design */
.admin-welcome {
	/* https://www.heropatterns.com/ ::: AUTUMN*/
	background-color: #d68f9c;
	opacity: 0.8;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='24' viewBox='0 0 88 24'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='autumn' fill='%23c81f3f' fill-opacity='0.4'%3E%3Cpath d='M10 0l30 15 2 1V2.18A10 10 0 0 0 41.76 0H39.7a8 8 0 0 1 .3 2.18v10.58L14.47 0H10zm31.76 24a10 10 0 0 0-5.29-6.76L4 1 2 0v13.82a10 10 0 0 0 5.53 8.94L10 24h4.47l-6.05-3.02A8 8 0 0 1 4 13.82V3.24l31.58 15.78A8 8 0 0 1 39.7 24h2.06zM78 24l2.47-1.24A10 10 0 0 0 86 13.82V0l-2 1-32.47 16.24A10 10 0 0 0 46.24 24h2.06a8 8 0 0 1 4.12-4.98L84 3.24v10.58a8 8 0 0 1-4.42 7.16L73.53 24H78zm0-24L48 15l-2 1V2.18A10 10 0 0 1 46.24 0h2.06a8 8 0 0 0-.3 2.18v10.58L73.53 0H78z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	position: relative;
	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		/* background-image: linear-gradient(60deg, #fff 20%, transparent 100%); */
		background-image: linear-gradient(60deg, #f2f0f0 48%, transparent 40%, transparent 100%);
		top: 0;
		left: 0;
		z-index: 0;
		border-radius: 0.5rem;
	}
	b,
	div {
		position: relative;
		z-index: 1;
	}
}

/* React editable wrap */
.wrapper-class {
	padding: 1rem;
	border: 1px solid #ccc;
}
.editor-class {
	background-color: #f8f5f5;
	padding: 1rem;
	border: 1px solid #ccc;
}
.toolbar-class {
	border: 1px solid #ccc;
}
