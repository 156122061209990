.modal {
	@apply w-full h-full flex justify-center items-center fixed top-0 left-0 z-40;
	.card {
		@apply bg-white rounded-md text-black max-h-90p mx-10 max-w-full overflow-y-auto flex flex-col;
		.card-header {
			@apply flex justify-end;
			.close {
				@apply text-black w-max self-end opacity-60 mt-1 mr-1 cursor-pointer;
			}
		}
		.content {
			@apply px-3 flex flex-col overflow-y-auto text-center;
		}
	}
	&:before {
		content: "";
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		z-index: -1;
	}
}
