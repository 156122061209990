.modal .card .content {
	min-width: 530px;
	.DraftEditor-root {
		min-height: 10rem;
	}
	form {
		label {
			text-align: left;
		}
		input {
			@apply h-9;
		}
		input[type="checkbox"] {
			@apply h-4;
		}
	}
	.users-add-user-modal,
	.comp-add-user-modal,
	.activity-add-product {
		input {
			@apply border border-gray-400 rounded focus:outline-none focus:border-primary h-9 pl-5 w-full;
		}
	}
}
.instructions {
	.DraftEditor-root {
		min-height: 10rem;
	}
}
