.data-sheet {
	@apply w-max relative ml-28 z-10;
	border-radius: 3px;
	td {
		border: 1px solid rgb(150, 150, 150) !important;
		span:first-child {
			padding: 2px 10px !important;
		}
		input {
			height: unset !important;
			width: 100% !important;
		}
		&.selected {
			border: 1px double rgb(33, 133, 208) !important;
		}
		&.activity-title {
			background-color: #bdd7ee !important;
			background-color: #a11a33 !important;
			color: rgb(34, 34, 34) !important;
			color: white !important;
		}
		&.bidsheet-title {
			background-color: #c6e0b4 !important;
			background-color: #c81f3f !important;
			color: rgb(34, 34, 34) !important;
			color: white !important;
		}
		&.activity-data {
			background-color: #d8d8d8 !important;
			color: rgb(54, 54, 54) !important;
		}
		&.bidsheet-data {
			background-color: #ffffff !important;
			color: rgb(34, 34, 34) !important;
		}
		&.error {
			background-color: #ff6868 !important;
		}
		&.warning {
			/* background-color: #fffd8f !important; */
		}
		&:first-child {
			@apply fixed w-28;
			height: fit-content !important;
			transform: translate(-112px, -0.5px);

			border-bottom: none !important;
			border-left: none !important;
			border-right: none !important;
		}
		&.string {
			text-align: left !important;
		}
		&.number,
		&.numberC,
		&.numberF {
			text-align: right !important;
		}
	}

	tr:first-child {
		td:first-child {
			height: 58px !important;
			display: flex;
			align-items: flex-end;
			justify-content: center;
		}
	}

	&::before {
		@apply fixed;
		content: "";
		background-color: #d8d8d8;
		border: 1px solid rgb(150, 150, 150) !important;
		left: -1px;
		width: calc(7rem + 2px);
		height: calc(100% - 17px);
	}
}
